import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Code,
  Heading,
  Spacer,
  Stack,
  StyleProps,
  Text,
  VStack,
  chakra,
} from "@chakra-ui/react";
import { AxiosError, isAxiosError } from "axios";
import { isError } from "remeda";
import _AccessDeniedImage from "../img/undraw_access_denied.svg?react";
import _AlertImage from "../img/undraw_alert.svg?react";
import _NoDataImage from "../img/undraw_empty.svg?react";
import _NotFoundImage from "../img/undraw_not_found.svg?react";
import _UnauthorizedImage from "../img/undraw_security.svg?react";
import _ServerErrorImage from "../img/undraw_server_error.svg?react";

export const NoDataImage = chakra(_NoDataImage);
export const UnauthorizedImage = chakra(_UnauthorizedImage);
export const ServerErrorImage = chakra(_ServerErrorImage);
export const NotFoundImage = chakra(_NotFoundImage);
export const AccessDeniedImage = chakra(_AccessDeniedImage);
export const AlertImage = chakra(_AlertImage);

type ErrorFallbackProps = {
  error: unknown;
  resetErrorBoundary?: () => void;
};

export function ErrorFallback({
  error,
  resetErrorBoundary,
}: ErrorFallbackProps) {
  return (
    <RouterErrorFallback
      error={error}
      resetErrorBoundary={resetErrorBoundary}
    />
  );
}

export function RouterErrorFallback({
  error,
  resetErrorBoundary,
}: Omit<ErrorFallbackProps, "resetErrorBoundary"> &
  Partial<Pick<ErrorFallbackProps, "resetErrorBoundary">>) {
  if (isAxiosError(error)) {
    return (
      <HttpErrorFallback
        statusCode={(error as AxiosError).response?.status}
        message={(error as AxiosError).message}
        resetErrorBoundary={resetErrorBoundary}
      />
    );
  }

  return (
    <Alert status="error" marginY={4}>
      <AlertIcon alignSelf={"start"} />
      <AlertDescription>
        <Stack role="alert">
          <Heading as="h2" size="md">
            Unexpected Application Error!
          </Heading>
          {isError(error) ? (
            <>
              <Heading as="h3" size="sm">
                {error.message}
              </Heading>
              {error.stack && (
                <Code as="pre" whiteSpace={"pre"}>
                  {error.stack}
                </Code>
              )}
            </>
          ) : (
            <Code as="pre">{JSON.stringify(error, null, 2)}</Code>
          )}

          {resetErrorBoundary && (
            <Button onClick={resetErrorBoundary}>Try again</Button>
          )}
        </Stack>
      </AlertDescription>
    </Alert>
  );
}

export function HttpErrorFallback({
  statusCode,
  message,
  resetButtonLabel = "Try again",
  resetErrorBoundary,
}: Partial<Pick<ErrorFallbackProps, "resetErrorBoundary">> & {
  message?: string;
  statusCode?: number;
  resetButtonLabel?: string;
}) {
  return (
    <VStack role="alert" paddingY={10}>
      <VStack spacing={4}>
        <HttpErrorImage statusCode={statusCode} />
        <HttpErrorTitle statusCode={statusCode} />
        <VStack width="md">
          <HttpErrorDescription statusCode={statusCode} />
          <Text as="pre" color="GrayText">
            {message}
          </Text>
        </VStack>
        <Spacer />
        {resetErrorBoundary && (
          <Button colorScheme="brand" onClick={resetErrorBoundary}>
            {resetButtonLabel}
          </Button>
        )}
      </VStack>
    </VStack>
  );
}

export function HttpErrorImage({
  statusCode,
  ...otherProps
}: { statusCode?: number } & StyleProps) {
  const props: StyleProps = { width: "md", fill: "brand-bg", ...otherProps };
  if (statusCode === 401) {
    return <UnauthorizedImage {...props} />;
  } else if (statusCode === 403) {
    return <AccessDeniedImage {...props} fill="red.300" />;
  } else if (statusCode === 400) {
    return <AlertImage {...props} fill="red.500" />;
  } else if (statusCode === 404) {
    return <NotFoundImage {...props} />;
  } else if (statusCode && [502, 500, 0].includes(statusCode)) {
    return <ServerErrorImage {...props} />;
  }

  return null;
}

function HttpErrorTitle({ statusCode }: { statusCode?: number }) {
  return (
    <Heading>
      {statusCode === 401
        ? "Unauthorized"
        : statusCode === 403
          ? "Access Denied"
          : statusCode === 404
            ? "Not Found"
            : statusCode === 400
              ? "Bad Request"
              : statusCode === 502
                ? "Service Unavailable"
                : "Server Error"}
    </Heading>
  );
}

function HttpErrorDescription({ statusCode }: { statusCode?: number }) {
  return (
    <Text>
      {statusCode === 401
        ? "Data could not be loaded from the server as it appears you are not fully authenticated."
        : statusCode === 403
          ? "Data could not be loaded as it appears you lack permissions."
          : statusCode === 404
            ? "Data could not be loaded as it appears it does not exist."
            : statusCode === 400
              ? "The request sent to the server was invalid."
              : statusCode === 502
                ? "The service is currently unavailable."
                : "Something went wrong on the server's side while attempting to fetch data."}
    </Text>
  );
}
